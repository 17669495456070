import React, { useEffect, useState } from "react";
import { momDashboardAPI } from "../../api";
import { toast } from "react-toastify";
import moment from "moment";
import CustomTableV2 from "../../Components/CustomTable/CustomTableV2";
import { f } from "../../utility/Formater";

const convertKey = {
  count: "payment_amount",
  percentage: "payment_amount_percentage",
};

const MoMDashboard = ({ type, monthType, nbfc, sourceBy, handleClose }) => {
  const [tableData, setTableData] = useState([]);
  const [tillDate, setTillDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [repaymentMonths, setRepaymentMonths] = useState([]);
  const [totalCount, setTotalCount] = useState(null);

  const repaymentTillDate = moment(repaymentMonths[0])
    .subtract(1, "month")
    .valueOf();

  let columns = [
    {
      label: <span className="text-nowrap">Due/Repayment</span>,
      render: (idx, rowType) => {
        if (rowType === "footer")
          return <span className="text-nowrap fw-semibold">Total</span>;
        if (tableData[idx]?.due_date === "sum_before_filtered_months") {
          return <span className="text-nowrap">Till {tillDate}</span>;
        }
        return (
          <span className="text-nowrap">
            {moment(tableData[idx]?.due_date, "MM/YYYY").format("MMMM,YY")}
          </span>
        );
      },
      customStyle: (_, rowType) => ({
        backgroundColor: rowType === "footer" ? "#BAB8FF" : "#d8e0ff",
      }),
      freeze: true,
      freezeWidth: 0,
    },
    !loading && {
      label: (
        <span className="text-nowrap">
          Till {moment(repaymentTillDate).format("MMMM,YY")}
        </span>
      ),
      render: (idx, rowType) => {
        if (rowType === "footer")
          return (
            <span className="text-nowrap fw-semibold">
              {f(totalCount?.["till_date"]?.[convertKey[type]], "cur")}
            </span>
          );
        return (
          <span className="text-nowrap">
            {f(
              tableData[idx]?.["till_date"]?.[convertKey[type]],
              type === "count" ? "cur" : "text"
            )}
          </span>
        );
      },
      customStyle: (idx, rowType) => {
        if (rowType === "footer") return { backgroundColor: "#BAB8FF" };
        return idx === 0 ? {} : { backgroundColor: "#d8ffe0" };
      },
    },
    ...(!loading
      ? []
      : new Array(4).fill({
          label: <span className="text-nowrap"> month</span>,
          render: () => {
            return <span className="text-nowrap">00000</span>;
          },
          customStyle: (_, rowType) => {
            if (rowType === "footer") return { backgroundColor: "#BAB8FF" };
          },
        })),
    ...(loading
      ? []
      : repaymentMonths.map((month) => {
          return {
            label: (
              <span className="text-nowrap">
                {moment(month).format("MMMM,YY")}
              </span>
            ),
            render: (idx, rowType) => {
              if (rowType === "footer")
                return (
                  <span className="text-nowrap fw-semibold">
                    {f(totalCount?.[month]?.[convertKey[type]], "cur")}
                  </span>
                );
              return (
                <span className="text-nowrap h-100 w-100">
                  {f(
                    tableData[idx]?.[month]?.[convertKey[type]],
                    type === "count" ? "cur" : "text"
                  )}
                </span>
              );
            },
            customStyle: (idx, rowType) => {
              if (rowType === "footer") return { backgroundColor: "#BAB8FF" };
              if (
                moment(tableData[idx].due_date, "MM/YYYY").valueOf() <= month ||
                tableData[idx].due_date === "sum_before_filtered_months"
              )
                return {};
              return {
                backgroundColor: "#d8ffe0",
              };
            },
          };
        })),
    {
      label: <span className="text-nowrap">Total</span>,
      render: (idx, rowType) => {
        if (rowType === "footer")
          return (
            <>
                <span className="text-nowrap fw-semibold">
                {f(
                    totalCount?.["total_repayment_amount"]?.[convertKey[type]],
                    "cur"
                )}
                </span>
                <span className='text-nowrap fw-semibold' style={{fontSize:10}}>({((totalCount?.["total_repayment_amount"]?.[convertKey[type]]/totalCount?.["total_expected_amount"])*100).toFixed(2)}%)</span>
            </>
          );
        return (
          <span className="text-nowrap">
            {f(
              tableData[idx]?.["total_repayment_amount"]?.[convertKey[type]],
              type === "count" ? "cur" : "text"
            )}
          </span>
        );
      },
      customStyle: (idx, rowType) => {
        if (rowType === "footer") return { backgroundColor: "#BAB8FF" };
      },
    },
    {
      label: <span className="text-nowrap">Expected</span>,
      render: (idx, rowType) => {
        if (rowType === "footer")
          return (
            <>
              <span className="text-nowrap fw-semibold">
                {f(totalCount?.["total_expected_amount"], "cur")}
              </span>
              <span
                className="text-nowrap fw-semibold"
                style={{ fontSize: 10 }}
              >
                (100%)
              </span>
            </>
          );
        if (type == "percentage")
          return <span className="text-nowrap">{"100%"}</span>;
        return (
          <span className="text-nowrap">
            {f(
              tableData[idx]?.["total_expected_amount"],
              type === "count" ? "cur" : "text"
            )}
          </span>
        );
      },
      customStyle: (idx, rowType) => {
        if (rowType === "footer") return { backgroundColor: "#BAB8FF" };
      },
    },
    {
      label: <span className="text-nowrap">Pending</span>,
      render: (idx, rowType) => {
        if (rowType === "footer")
          return (
            <>
              <span className="text-nowrap fw-semibold">
                {f(totalCount?.["pending_amount"]?.[convertKey[type]], "cur")}
              </span>
              <span
                className="text-nowrap fw-semibold"
                style={{ fontSize: 10 }}
              >
                (
                {(
                  (totalCount?.["pending_amount"]?.[convertKey[type]] /
                    totalCount?.["total_expected_amount"]) *
                  100
                ).toFixed(2)}
                %)
              </span>
            </>
          );
        // return <span className='text-nowrap fw-semibold'>{f(totalCount?.["pending_amount"]?.[convertKey[type]],"cur")}</span>
        return (
          <span className="text-nowrap">
            {f(
              tableData[idx]?.["pending_amount"]?.[convertKey[type]],
              type === "count" ? "cur" : "text"
            )}
          </span>
        );
      },
      customStyle: (idx, rowType) => {
        if (rowType === "footer") return { backgroundColor: "#BAB8FF" };
      },
    },
  ].filter(Boolean);

  async function fetchTableData() {
    try {
      setLoading(true);
      let payload = {
        filter: monthType,
        nbfc_list: nbfc,
        sourced_by: sourceBy,
      };
      if (sourceBy === "All") {
        delete payload.sourced_by;
      }
      const { data } = await momDashboardAPI(payload);
      const response = formateMomData(data?.bucket_data || {});
      setTableData(response.tableData);
      setRepaymentMonths(response.totalRepaymentMonths);
      setTillDate(response.due_till_month_is);
      setTotalCount(response.totalCount);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (monthType && nbfc && sourceBy) {
      fetchTableData();
    }
  }, [monthType, nbfc, sourceBy]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <div>
      <CustomTableV2
        columns={columns}
        tableData={tableData}
        maxHeight={"60dvh"}
        loadingRows={6}
        isLoading={loading}
        borderBottom
        footerColumns={columns}
        tableFooterData={type === "count" ? [totalCount] : []}
      />
    </div>
  );
};

function formateMomData(data) {
  let totalRepaymentMonths = new Set();
  let tableData = [];
  Object.keys(data).map((due_date) => {
    let rowData = { due_date };
    if (due_date !== "sum_before_filtered_months")
      rowData["due_date_ms"] = moment(due_date, "DD/YYYY").valueOf();
    let repayment_months = Object.keys(data[due_date]["repayment_months"]);
    repayment_months.forEach((item) => {
      if (
        item !== "repayment_amount_before_filtered_months" &&
        item !== "repayment_amount_before_filtered_months_percentage"
      ) {
        const timeMs = moment(item, "MM/YYYY").valueOf();
        totalRepaymentMonths.add(timeMs);
        rowData[timeMs] = data[due_date]["repayment_months"][item];
      } else {
        let key =
          item === "repayment_amount_before_filtered_months"
            ? "payment_amount"
            : "payment_amount_percentage";
        if (!rowData["till_date"]) rowData["till_date"] = {};
        rowData["till_date"][key] = data[due_date]["repayment_months"][item];
      }
    });
    rowData["total_repayment_amount"] = {
      payment_amount: data[due_date]["total_repayment_amount"],
      payment_amount_percentage:
        data[due_date]["total_repayment_amount_percentage"],
    };
    rowData["pending_amount"] = {
      payment_amount: data[due_date]["pending_amount"],
      payment_amount_percentage: data[due_date]["pending_amount_percentage"],
    };
    rowData["total_expected_amount"] = data[due_date]["total_expected_amount"];
    tableData.push(rowData);
  });

  let totalRowData = {};
  for (let row of tableData) {
    Object.keys(row).forEach((key) => {
      if (key !== "due_date") {
        if (key === "total_expected_amount") {
          totalRowData[key] =
            Number(totalRowData[key] ?? 0) + (Number(row[key]) ?? 0);
        } else {
          if (!totalRowData[key]) totalRowData[key] = { payment_amount: 0 };
          totalRowData[key]["payment_amount"] +=
            Number(row[key]?.payment_amount) ?? 0;
        }
      }
    });
  }

  return {
    totalRepaymentMonths: [...totalRepaymentMonths].sort((a, b) => a - b),
    tableData: tableData.sort((a, b) => a.due_date_ms - b.due_date_ms),
    due_till_month_is: moment(tableData[1]["due_date"], "MM/YYYY")
      .subtract(1, "month")
      .format("MMMM"),
    totalCount: totalRowData,
  };
}

export default MoMDashboard;
