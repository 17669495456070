import { CheckBox } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Slide,
  TextField,
} from "@mui/material";
import { includes } from "lodash";
import React, { useEffect, useState } from "react";
import { addOtherDocuments } from "../api";
import useApplication from "../hooks/useApplication";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const otherDocsInit = {
  relationship_proof: { checked: false, disabled: false },
  affidavit: { checked: false, disabled: false },
  fi_form: { checked: false, disabled: false },
  third_party_pan: { checked: false, disabled: false },
  third_party_aadhaar_front: { checked: false, disabled: false },
  third_party_aadhaar_back: { checked: false, disabled: false },
  third_party_other_details: { checked: false, disabled: false },
  dual_form_guarantor: { checked: false, disabled: false },
  filled_loan_agreement: { checked: false, disabled: false }
}

function AddOtherDocs({ open, handleClose, handleOtherDocsUpload,AllDocuments, loanDocs }) {
  const { updateDetails } = useApplication();
  const [isOtherDocValid, setisOtherDocValid] = useState(false);
  const [otherDocs, setOtherDocs] = useState({...otherDocsInit});
  const [isOther, setIsOther] = useState(false);
  const [input, setInput] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [showError, setshowError] = useState({
    input: false,
    exist: false,
  });

  function formatString(str) {
    return str
      ?.split("_")
      ?.map((item) => item?.[0]?.toUpperCase() + item?.slice(1)?.toLowerCase())
      ?.join(" ");
  }

  function handleInputChange(e) {
    let val = e.target.value;
    setInput(val);
    val = val?.toLowerCase()?.replaceAll(" ", "_");
    if (Object.keys(otherDocs)?.some((item) => item === val)) {
      setisOtherDocValid(false);
    } else setisOtherDocValid(true);
    setshowError({
      input: false,
      exist: false,
    });
  }

  async function handleSubmit() {
    if (
      !confirm &&
      !isOther &&
      Object?.values(otherDocs)?.some((item) => item?.checked == true)
    ) {
      setConfirm(true);
      let optDocs = {};
      Object.keys(otherDocs || {})?.map((item) => {
        if (otherDocs?.[item]?.disabled === false) {
          optDocs[item] = otherDocs?.[item];
        }
      });
      setOtherDocs((prev) => {
        return {
          ...optDocs
        };
      });
    } else if (!confirm && isOther && isOtherDocValid && input?.length) {
      let optDocs = {};
      let allDocs = [];
      Object.keys(AllDocuments)?.map(item => {
        if(Array.isArray(AllDocuments?.[item])) allDocs = [...allDocs,...(AllDocuments?.[item] || [])]
      })
      // console.log('allDocs -> ',allDocs);
      if(allDocs?.some(itm => itm?.name === input?.trim()?.toLowerCase()?.replaceAll(" ", "_"))){
        setshowError({
          input: input?.length === 0,
          exist: true,
        });
        return;
      }
      Object.keys(otherDocs || {})?.map((item) => {
        if (otherDocs?.[item]?.disabled === false) {
          optDocs[item] = otherDocs?.[item];
        }
      });
      setOtherDocs((prev) => {
        return {
          ...optDocs,
          [input]: { checked: true, disabled: false },
        };
      });
      setConfirm(true);
    } else if (!confirm) {
      setshowError({
        input: input?.length === 0,
        exist: true,
      });
    }
    if (confirm) {
      let payload = [];
      Object.keys(otherDocs)?.map((item) => {
        if (otherDocs?.[item]?.checked)
          payload.push(item?.trim()?.toLowerCase()?.replaceAll(" ", "_"));
      });
      handleOtherDocsUpload(payload);
      setshowError({
        input: false,
        exist: false,
      });
      setConfirm(false);
      handleClose();
    }
  }

  useEffect(() => {
    let otherDocs = { ...otherDocsInit };
    loanDocs?.map((item) => {
      if (item?.category === "other_documents") {
        otherDocs[item?.name] = {
          checked: true,
          disabled: true,
        };
      }
    });
    setOtherDocs(otherDocs);
  }, [open, loanDocs]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setshowError({
          input: false,
          exist: false,
        });
        setConfirm(false);
        handleClose();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {"Select the documents to add for this application"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <FormGroup>
            {Object.keys(otherDocs)?.map((item) => {
              return (
                <FormControlLabel
                  checked={otherDocs?.[item]?.checked}
                  disabled={otherDocs?.[item]?.disabled}
                  control={<Checkbox />}
                  label={formatString(item)}
                  onChange={(e, val) => {
                    setOtherDocs((prev) => {
                      return {
                        ...prev,
                        [item]: { ...otherDocs?.[item], checked: val },
                      };
                    });
                  }}
                />
              );
            })}
            {!confirm && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "20% 60% 20%",
                  justifyContent: "start",
                  height: 50,
                  alignItems: "baseline",
                }}
              >
                <FormControlLabel
                  checked={isOther}
                  control={<Checkbox />}
                  label="Others"
                  onChange={(e, val) => {
                    setIsOther(val);
                    // setOtherDocs((prev)=>{
                    //     return {
                    //         ...prev,
                    //         others:val
                    //     }
                    // })
                  }}
                />
                {isOther && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent:'center',
                        alignSelf: "center",
                      }}
                    >
                      <input
                        placeholder="Write doc name"
                        style={{
                          border: "1px solid rgba(96, 89, 89, 1)",
                          borderRadius: "3px",
                          height: 30,
                        }}
                        onChange={handleInputChange}
                      />
                      {(showError.exist || showError.input) && (
                        <p
                          style={{
                            fontSize: 10,
                            color: "rgba(255, 34, 34, 1)",
                          }}
                        >
                          {showError.input
                            ? "*Please enter a name"
                            : "*Name already exists try different name"}
                        </p>
                      )}
                    </div>
                    <CheckIcon
                      sx={{
                        color: isOtherDocValid
                          ? "rgba(22, 152, 10, 1)"
                          : "rgba(96, 89, 89, 1)",
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </FormGroup>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setshowError({
              input: false,
              exist: false,
            });
            setConfirm(false);
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit}>{!confirm ? "Next" : "Confirm"}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddOtherDocs;
