import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment/moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { baseURL, exportCashreceipt, fetchCashReciepts } from "../../api";
import CustomTable from "../../Components/CustomTable/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { UserContext } from "../../Context/UserContext";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@mui/icons-material/GetApp";
import { toast } from "react-toastify";
import { capitalize, f, formatDate } from "../../utility/Formater";
import AddCashReceipt from "../../Components/Dialogs/AddCashReceipt";
import CountUp from "react-countup";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import AdvancedSort from "../../Components/Sort/AdvancedSort";
import CashReceiptFilter from "../../Components/Sort/CashReceiptFilter";
import { format } from "date-fns";

const columnsRemovedForNBFC = {
  "Application Date": 1,
  NBFC: 1,
  Status: 1,
  // "Overall Status":1,
  Stage: 1,
  agent: 1,
};

const filterOptions = [
  "dealer_name",
  "partner_type",
  "customer_payment_date",
  "agent_name",
  "agent_code",
];

function CashReceiptTable() {
  const [tableData, setTableData] = useState([]);
  const [totalCount, setCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [genLoader, setGenLoader] = useState(null);
  const [searchTxt, setSearchTxt] = useState("");
  const navigate = useNavigate();
  const [filter, setFilter] = useState("all");
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [tableTotal, setTableTotal] = useState({
    total_received_by_partner: 0,
    total_received_by_nbfc: 0,
    total_pending: 0,
  });

  const [editData, setEditData] = useState({
    open: false,
  });
  const [openFilter, setOpenFilter] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const location = useLocation();
  const filterDialogRef = useRef();

  function handlePageChange(page, limit, isLimit) {
    if (page) {
      setPage(page);
    } else if (!!isLimit) {
      setPage(1);
    }
    let type = localStorage.getItem("CashReciptFilter") ? "filter" : null;
    setLimit(limit);
    fetchTableData(
      page,
      limit,
      type,
      JSON.parse(localStorage.getItem("CashReciptFilter")) || null,
      localStorage.getItem("cashReceiptSearch") || null,
      true
    );
  }

  let columns = [
    {
      label: "Application Id",
      value: "application_id",
      copy: true,
      width: 180,
    },
    {
      label: "Name",
      value: "customer_name",
      width: 180,
      // left: 65,
      freeze: true,
    },
    {
      label: "Amount Received by Dealer",
      render: (i) => {
        return f(tableData[i]?.amount, "cur");
      },
      value: "amount",
      width: 130,
    },
    {
      label: "Amount Received by NBFC",
      render: (i) => {
        return f(tableData[i]?.amount_received_by_nbfc, "cur");
      },
      value: "amount",
      width: 130,
    },
    {
      label: "NBFC Recieved Date",
      render: (i) => {
        return tableData[i]?.money_received_nbfc_date
          ? moment(tableData[i]?.money_received_nbfc_date).format("DD/MM/YYYY")
          : "";
      },
      value: "amount",
      width: 130,
    },
    {
      label: "Duration Held By Dealer(days)",
      render: (i) => {
        return tableData[i]?.duration_held_by_dealer;
      },
      value: "amount",
      width: 130,
    },
    {
      label: "Customer Payment Date",
      render: (i) => {
        return f(
          new Date(tableData[i]?.transaction_date) === "Invalid Date"
            ? null
            : tableData[i]?.transaction_date,
          "date"
        );
      },
      value: "transaction_date",
      width: 120,
    },
    {
      label: "Receipt Creation Date",
      render: (i) => {
        return f(
          new Date(tableData[i]?.createdAt) === "Invalid Date"
            ? null
            : tableData[i]?.createdAt,
          "date"
        );
      },
      value: "transaction_date",
      width: 120,
    },
    {
      label: "Partner Type",
      render: (i) => {
        return f((tableData[i]?.partner_type || "").toUpperCase());
      },
      value: "partner_type",
      width: 120,
    },
    {
      label: "Dealer Name",
      render: (i) => {
        return f((tableData[i]?.dealer_name || "").toUpperCase());
      },
      value: "dealer_name",
      width: 120,
    },
    {
      label: "Agent Name",
      render: (i) => {
        return f((tableData[i]?.agent_name || "").toUpperCase());
      },
      value: "agent_name",
      width: 120,
    },
    {
      label: "Received by NBFC",
      render: (i) => {
        if (tableData[i].received_by_nbfc == 2) {
          return f(true, "bool");
        } else if (tableData[i].received_by_nbfc == 1) {
          return (
            <span style={{ color: "#9E00FF" }}>
              <HourglassBottomIcon style={{ fontSize: "17px" }} /> Partially
              paid
            </span>
          );
        } else if (tableData[i].received_by_nbfc == 0) return f(false, "bool");
      },
      value: "received_by_nbfc",
      width: 110,
    },
    {
      label: "Source",
      render: (i) => {
        if (
          ["DEALER", "AGENT"]?.includes(tableData[i]?.created_by?.user_type)
        ) {
          return (
            <Chip
              size="small"
              icon={<PhoneAndroidOutlinedIcon sx={{ fontSize: 12 }} />}
              label="APP"
              color="secondary"
              variant="outlined"
            />
          );
        } else {
          return (
            <Chip size="small" label="Embedded-tools" variant="outlined" />
          );
        }
      },
      value: "created_by.user_type",
      width: 120,
    },
  ];

  columns = columns.concat([
    {
      // label: "Add transaction",
      render: (index) => {
        return (
          <Button
            variant="contained"
            disableElevation
            style={{ fontSize: "10px" }}
            onClick={() => {
              setEditData({
                open: true,
                receipt_id: tableData[index]?._id,
                application_id: tableData[index]?.application_id || "",
                amount: tableData[index]?.amount || "",
                date:
                  new Date(tableData[index]?.transaction_date) ===
                  "Invalid Date"
                    ? null
                    : tableData[index]?.transaction_date,
                partner_type: (
                  tableData[index]?.partner_type || ""  
                ).toUpperCase(),
                received_nbfc: tableData[index]?.received_by_nbfc || 0,
                nbfc_received_amount:
                  (tableData[index]?.amount || 0) -
                  (tableData[index]?.amount_received_by_nbfc || 0),
                amount_paid: tableData[index]?.amount_received_by_nbfc,
                created_user:
                  tableData[index]?.created_by?.user_type || "EMBEDDED_TOOLS",
                received_nbfc_date:
                  new Date(
                    tableData[index]?.money_received_by_nbfc?.nbfc_received_date
                  ) === "Invalid Date"
                    ? null
                    : tableData[index]?.money_received_by_nbfc
                        ?.nbfc_received_date,
              });
            }}
            startIcon={<EditIcon fontSize="small" />}
          >
            Edit
          </Button>
        );
      },
      width: 100,
    },
  ]);

  const addFilterData = (data) => {
    let final_data = [];
    data &&
      data.map((ele) => {
        final_data.push({
          dealer_name: ele?.dealer_name,
          partner_type: ele?.partner_type,
          customer_payment_date: ele?.transaction_date,
        });
      });

    final_data.map((ele) => {
      ele = {
        dealer_name: [...new Set(ele?.dealer_name)],
        partner_type: [...new Set(ele?.partner_type)],
      };
    });
    localStorage.setItem("CashReceiptFilter", JSON.stringify(final_data));
  };

  const fetchTableData = async (
    page,
    limit,
    type,
    typeValue,
    search,
    pageChange
  ) => {
    if (pageChange) setPageLoading(true);
    else setLoading(true);
    try {
      let params = `&page=${page}`;
      if (limit) params += `&limit=${limit}`;
      if (type === "filter" && typeValue)
        params += `&filters=${encodeURIComponent(JSON.stringify(typeValue))}`;
      if (search) params += `&search=${search}`;
      let { data } = await fetchCashReciepts(params);
      console.log("data => ", data);
      addFilterData(data?.result || []);
      setTableData(data?.result || []);
      setCount(data?.total_count);
      setTableTotal({
        total_pending: data?.total_pending || 0,
        total_received_by_nbfc: data?.total_received_by_nbfc || 0,
        total_received_by_partner: data?.total_received_by_partner || 0,
        lastUpdatedReceiptDate: data?.lastUpdatedReceiptDate,
      });
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    }
    if (pageChange) setPageLoading(false);
    else setLoading(false);
  };

  const getDateFromFilter=(filter)=>{
    let startDateMS, endDateMS;
    let currentDate = new Date();

    if (filter === "today") {
      startDateMS = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ).getTime();
      endDateMS = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      ).getTime();
    } else if (filter === "current_month") {
      startDateMS = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      ).getTime();
      endDateMS = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getTime();
    } else if (filter === "last_seven_days") {
      startDateMS = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 6
      ).getTime();
      endDateMS = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      ).getTime();
    } else if (filter === "last_month") {
      startDateMS = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      ).getTime();
      endDateMS = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getTime();
    }

    if(startDateMS && endDateMS) 
      return { from:startDateMS , to:endDateMS }
    return null;
  }

  const IntialDataFetch = () => {
    if (location?.state?.filter === "agent_name" && filterDialogRef.current) {
      filterDialogRef.current?.handeAgentNameFilter(location?.state?.data?.name,getDateFromFilter(location?.state?.date));
      return;
    }
    if (location?.state?.filter === "agents" && filterDialogRef.current) {
      filterDialogRef.current?.handeAgentsFilter(getDateFromFilter(location?.state?.date));
      return;
    }
    if (localStorage.getItem("CashReciptFilter")) {
      fetchTableData(
        page ?? 1,
        50,
        "filter",
        JSON.parse(localStorage.getItem("CashReciptFilter"))
      );
    } else {
      fetchTableData(page ?? 1, 50);
    }
  };

  const handleDownload = async () => {
    try {
      let params = "";
      setGenLoader("EXPORT");
      let val = localStorage.getItem("CashReciptFilter");
      console.log("export => ", val);
      if (val) {
        let params_value = JSON.parse(val);
        params += `&filters=${encodeURIComponent(
          JSON.stringify(params_value)
        )}`;
      }
      let { data } = await exportCashreceipt(params || "");
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Cash-receipt.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Failed while exporting data"
      );
    }
    setGenLoader(null);
  };

  useEffect(() => {
    IntialDataFetch();
  }, []);

  const handleSearch = (value) => {
    if (value !== "") {
      setSearch(true);
      fetchTableData(1, limit, null, null, value, false);
      localStorage.setItem("cashReceiptSearch", value);
      localStorage.removeItem("CashReciptFilter");
    } else {
      setSearchTxt("");
      setSearch(false);
      fetchTableData(
        1,
        limit,
        localStorage.getItem("CashReciptFilter") ? "filter" : null,
        JSON.parse(localStorage.getItem("CashReciptFilter"))
      );
    }
  };

  useEffect(() => {
    if (!isSearch) {
      localStorage.removeItem("cashReceiptSearch");
    }
  }, [isSearch]);

  return (
    <>
      <div className="px-3">
        <Grid container spacing={2} className="mb-3 pt-3">
          <Grid item xs={12} sm={2}>
            <div className="over-view-card border">
              <p className="head">Total Received BY dealer</p>
              <p className="amount">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={tableTotal?.total_received_by_partner} />
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className="over-view-card border">
              <p className="head">Total Received by NBFC</p>
              <p className="amount">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={tableTotal?.total_received_by_nbfc} />
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className="over-view-card border">
              <p className="head">Total Pending for NBFC</p>
              <p className="amount">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={tableTotal?.total_pending} />
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div
              className="over-view-card border"
              style={{
                width: "230px",
              }}
            >
              <p className="head">Last Updated</p>
              <p className="amount">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  moment(tableTotal?.lastUpdatedReceiptDate).format(
                    "DD/MM/yyyy hh:mm:ss A"
                  )
                )}
              </p>
            </div>
          </Grid>
        </Grid>
        <div
          className="d-flex py-3"
          style={{ justifyContent: "space-between" }}
        >
          <div className="d-flex flex-row align-items-center">
            <TextField
              className="search-bar-table me-2 other-collection-searchbar"
              id="input-with-icon-textfield"
              placeholder="Search  Application Id/ Customer Name"
              variant="standard"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "gray" }} />,
                disableUnderline: true,
                style: {
                  color: "black",
                  fontSize: "13px",
                  fontWeight: 400,
                  height: 25,
                  width: 300,
                },
              }}
              value={searchTxt}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e.target.value);
                }
              }}
              onChange={(e) => {
                setSearchTxt(e.target.value);
              }}
            />
            {!Boolean(localStorage.getItem("CashReciptFilter")) ? (
              <Button
                sx={{ color: "gray" }}
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                <FilterAltIcon /> filters
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                >
                  <span className="zoom-animation d-flex flex-row align-items-center">
                    <FilterAltIcon fontSize="small" /> <span>Filters</span>
                  </span>
                </Button>
                <span
                  className="pointer ms-2 d-flex flex-row align-items-center"
                  onClick={() => {
                    localStorage.removeItem("filterDataCashReciptFilter");
                    localStorage.removeItem("CashReciptFilter");
                    fetchTableData(1, 50, null, null, null, true);
                    setOpenFilter(false);
                  }}
                >
                  <HighlightOffIcon style={{ fontSize: 20 }} />
                </span>
              </>
            )}
          </div>
          <div>
            <Button
              disableElevation
              className="me-2"
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleDownload();
              }}
              startIcon={<CircularProgress />}
              startIcon={
                genLoader === "EXPORT" ? (
                  <CircularProgress size={15} />
                ) : (
                  <GetAppOutlinedIcon />
                )
              }
            >
              {genLoader === "EXPORT" ? "Exporting" : "Export"}
            </Button>
            <Button
              variant="contained"
              disableElevation
              style={{ fontSize: "10px" }}
              onClick={() => {
                setEditData({
                  open: true,
                });
              }}
              startIcon={<AddIcon />}
            >
              Add transaction
            </Button>
          </div>
        </div>
        <CustomTable
          tableData={tableData}
          tableHeight="65vh"
          columns={columns}
          defaultLimit={50}
          totalCount={totalCount}
          isLoading={isLoading}
          // serialNo={true}
          // stickSL={true}
          allowAll={true}
          pageLoading={isPageLoading}
          onPageChange={handlePageChange}
          onLimitChange={(page, limit) =>
            handlePageChange(page, limit, "limit")
          }
        />
      </div>

      <CashReceiptFilter
        name="CashReciptFilter" //filterDataCashReciptFilter
        ref={filterDialogRef}
        open={openFilter}
        handleClose={() => {
          setOpenFilter(false);
        }}
        onApply={(filter) => {
          localStorage.setItem("CashReciptFilter", JSON.stringify(filter));
          fetchTableData(1, limit, "filter", filter, null, true);
          setOpenFilter(false);
        }}
        options={filterOptions}
      />
      <AddCashReceipt
        data={editData}
        setData={setEditData}
        onSubmit={() => {
          fetchTableData(
            page,
            limit,
            localStorage.getItem("CashReciptFilter") ? "filter" : null,
            JSON.parse(localStorage.getItem("CashReciptFilter")) || null,
            localStorage.getItem("cashReceiptSearch") || null,
            true
          );
        }}
        handleClose={() => {
          setEditData({
            open: false,
          });
        }}
      />
    </>
  );
}

export default CashReceiptTable;
