import React, { useState, useEffect, useContext } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  CssBaseline,
  TextField,
  InputLabel,
  MenuItem,
  Grid,
  Box,
  Container,
  StepContent,
} from "@mui/material";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import useAuth from "../hooks/useAuth";
import Paper from "@mui/material/Paper";
import AddIco from "../../assets/add.svg";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  createAnchor,
  updateDealerShared,
  fetchLatLng,
  createAnchorV2,
  anchorOCR,
  anchorPennyDrop,
  anchorCrifReport,
} from "../../api";
import { GlobalContext } from "../../Context/GlobalContext";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import BasicDetails from "./Steps/BasicDetails";
import BankDetails from "./Steps/BankDetails";
import ContactDetails from "./Steps/ContactDetails";
import LoadingOver from "../../Components/Loading";
import useAnchor from "../../hooks/useAnchor";

const theme = createTheme();
const steps = ["Basic Details", "Bank Details", "Contact Details"];
function getStepContent(step, setActiveStep, args = {}) {
  switch (step) {
    case 0:
      return <BasicDetails />;
    case 1:
      return <BankDetails pennyDropLogs={args?.pennyDropLogs} />;
    case 2:
      return <ContactDetails />;
    default:
      throw new Error("Unknown step");
  }
}

function CreateAnchor() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // const { createAnchor } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const { dealerDetailState } = useContext(GlobalContext);
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const [overLoading, setOverLoading] = useState({
    open: false,
    text: "",
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [failedDocs, setFailedDocs] = useState([]);
  const [pennyDropLogs, setPennyDropLogs] = useState([]);

  const { handleShopImagesUpload } = useAnchor();

  const handleLoading = (open, text = "") => {
    setOverLoading({
      open,
      text,
    });
  };

  const handleNext = () => {
    if (activeStep === 2) {
      handleCreateAnchor();
    } else {
      if (activeStep === 0) {
        handleBasicDetails();
      } else if (activeStep === 1) {
        handlePennyDrop();
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBasicDetails = async () => {
    try {
      if (dealerDetails?.pan_images?.length > 0) {
        if (!dealerDetails?.pan_updated) {
          if (dealerDetails?.pan_ocr_results?.failed?.length === 0) {
            setActiveStep(activeStep + 1);
            return;
          } else {
            toast.error("OCR failed for some PAN. Please remove or try again");
            return;
          }
        }
        handleLoading(true, "Running OCR");

        let payload = {
          files: dealerDetails?.pan_images?.map((file) => file?.fileLink),
        };
        let { data } = await anchorOCR(payload);
        let state = { ...dealerDetails };
        console.log(data);
        data?.failed?.forEach((val) => {
          state.pan_images[val.index].error = "OCR failed";
        });
        setDealerDetails({
          ...state,
          pan_updated: false,
          pan_ocr_results: {
            failed: data?.failed,
            success: data?.success,
            all: (data?.failed || [])
              .concat(data?.success || [])
              .sort((a, b) => a.index - b.index),
          },
        });

        if (data?.failed?.length === 0) {
          setActiveStep(activeStep + 1);
        } else {
          toast.error("OCR failed for some PAN. Please remove or try again");
        }
      } else {
        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
    handleLoading(false, "");
  };

  const handlePennyDrop = async () => {
    try {
      if (
        dealerDetails?.account_number &&
        dealerDetails?.account_number?.length !== 0 &&
        dealerDetails?.ifsc_code &&
        dealerDetails?.ifsc_code !== 0
      ) {
        if (!dealerDetails?.bank_details_changed) {
          if (dealerDetails?.penny_drop_data?.status) {
            setActiveStep(activeStep + 1);
            return;
          }
        }
        handleLoading(true, "Processing Penny Drop");

        let { data } = await anchorPennyDrop({
          account_number: dealerDetails?.account_number,
          ifsc_code: dealerDetails?.ifsc_code,
        });

        if (data?.status) {
          toast.success("Penny Drop successfull");
          setDealerDetails({
            ...dealerDetails,
            bank_details_changed: false,
            penny_drop_data: data || {},
          });
          setActiveStep(activeStep + 1);
        }
      } else {
        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
      setPennyDropLogs(error?.response?.data?.pennyDropLogs);
    }
    handleLoading(false);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCreateAnchor = async (e) => {
    // e.preventDefault();
    if (
      [undefined, "", null]?.includes(
        dealerDetails?.outlet_details?.[0]?.relation_manager_details?.[0]
          ?.mobile
      ) ||
      [undefined, "", null]?.includes(
        dealerDetails?.outlet_details?.[0]?.relation_manager_details?.[0]?.name
      )
    ) {
      toast.error("Check relational manager number/name");
      return;
    }
    let crifResult = null
    try {
      handleLoading(true,"Fetching Crif Report")
      let nameArray = dealerDetails?.name?.split(" ");
      let crifPayload = {
        firstName: nameArray?.[0],
        middleName: "",
        lastName:
          nameArray?.length > 1 ? nameArray?.[nameArray?.length - 1] : "",
        dob: dealerDetails?.dob,
        phone1:
          dealerDetails?.outlet_details?.[0]?.relation_manager_details?.[0]
            ?.mobile,
        kyc: {
          pan: dealerDetails?.pan_ocr_results?.success?.[0]?.response?.value
            ?.number,
        },
        address: dealerDetails?.outlet_details?.[0]?.address,
        city: dealerDetails?.outlet_details?.[0]?.city,
        state: dealerDetails?.outlet_details?.[0]?.state,
        pin: dealerDetails?.outlet_details?.[0]?.pincode,
        country: dealerDetails?.outlet_details?.[0]?.country,
        consent: "Y",
      };
      const { data } = await anchorCrifReport({
        payload: crifPayload
      })
      crifResult = data?.credit_pull
    } catch (err) {
      crifResult = {
        message: "Internal Server Error"
      }
    }
    finally{
      handleLoading(false,"")
    }
    try {
      setLoading(true);
      let payload = {
        anchor_type: "DEALER",
        name: dealerDetails?.name || "",
        firm_type: dealerDetails?.type || "",
        dealership_name: dealerDetails?.dealership_name,
        dob: dealerDetails?.dob,
        credit_pull: crifResult,
        gst: dealerDetails?.gstin,
        rto_name: dealerDetails?.rto_name,
        monthly_capacity: dealerDetails?.monthly_capacity,
        ...(dealerDetails?.gst_files?.length > 0
          ? {
              anchor_gstin_pdf: {
                name: "anchor_gstin_pdf",
                file: (dealerDetails?.gst_files || [])?.map(
                  (file) => file?.fileLink
                ),
              },
            }
          : {}),

        ...(dealerDetails?.dealer_images?.length > 0
          ? {
              dealer_image: {
                name: "anchor_dealer_image",
                file: (dealerDetails?.dealer_images || [])?.map(
                  (file) => file?.fileLink
                ),
              },
            }
          : {}),
        ...(dealerDetails?.pan_images?.length > 0
          ? {
              personal_pan_details: {
                name: "anchor_personal_pan",
                file: (dealerDetails?.pan_images || [])?.map(
                  (file) => file?.fileLink
                ),
                details: (dealerDetails?.pan_ocr_results?.success || []).map(
                  (val) => val?.response?.value
                ),
              },
            }
          : {}),
        ...(dealerDetails?.company_pan_image?.length > 0
          ? {
              company_pan_image: {
                name: "anchor_company_pan",
                file: (dealerDetails?.company_pan_image || [])?.map(
                  (file) => file?.fileLink
                ),
              },
            }
          : {}),
        ...(dealerDetails?.aadhaar_images?.length > 0
          ? {
              aadhar_image: {
                name: "aadhar_image",
                file: (dealerDetails?.aadhaar_images || [])?.map((file) => {
                  return {
                    aadhaar_front: file?.aadhaar_front?.fileLink,
                    aadhaar_back: file?.aadhaar_back?.fileLink,
                  };
                }),
              },
            }
          : {}),
        ...(dealerDetails?.trade_license?.length > 0
          ? {
              trade_license_pdf: {
                name: "anchor_trade_license",
                file: (dealerDetails?.trade_license || [])?.map(
                  (file) => file?.fileLink
                ),
              },
            }
          : {}),
        ...(dealerDetails?.shop_image?.length > 0
          ? {
              shop_image: {
                name: "anchor_shop_image",
                file: (dealerDetails?.shop_image || [])?.map(
                  (file) => file?.fileLink
                ),
              },
            }
          : {}),
        ...(dealerDetails?.agreement?.length > 0
          ? {
              anchor_agreement: {
                name: "anchor_agreement",
                file: (dealerDetails?.agreement || [])?.map(
                  (file) => file?.fileLink
                ),
              },
            }
          : {}),
        ...(dealerDetails?.other_image?.length > 0
          ? {
              other_image: {
                name: "anchor_other_image",
                file: (dealerDetails?.other_image || [])?.map(
                  (file) => file?.fileLink
                ),
              },
            }
          : {}),
        oem_details: dealerDetails?.oems?.map((oem) => {
          return {
            oem_id: oem?.oem_id,
            oem_name: oem?.oem_name,
            battery_info: Object.keys(oem?.battery_type || {})?.map((key) => {
              return {
                battery_type: oem?.battery_type[key]?.name,
                max_loan_amount: oem?.battery_type[key]?.amount,
              };
            }),
            interest_rate: oem?.interest_rate,
            processing_fees:
              oem?.pf_type === "AMOUNT" ? oem?.processing_fees : null,
            processing_fees_percent:
              oem?.pf_type === "PERCENTAGE"
                ? oem?.processing_fees_percent
                : null,
            hold_back_amount: {
              per_file: oem?.hold_back_per_file_amount,
              total: oem?.hold_back_total_amount,
            },
            subvention: {
              amount: oem?.sb_type === "AMOUNT" ? oem?.subvention_amount : null,
              percent:
                oem?.sb_type === "PERCENTAGE" ? oem?.subvention_perc : null,
              gst_type: oem?.gst_type,
            },
            gps_charges: oem?.gps_charges,
          };
        }),
        ...((dealerDetails?.account_number &&
          dealerDetails?.account_number !== "") ? {
            bank_details: [
              {
                account_number: dealerDetails?.account_number,
                ifsc_code: dealerDetails?.ifsc_code,
                beneficiary_name: dealerDetails?.beneficiary_name,
                bank_name: dealerDetails?.bank_name,
                account_type: dealerDetails?.account_type,
                penny_drop_data: {
                  verified:
                    dealerDetails?.penny_drop_data?.data?.verified ||
                    dealerDetails?.penny_drop_data?.data?.bankTxnStatus,
                  verified_at: dealerDetails?.penny_drop_data?.data?.verified
                    ? "Digio"
                    : "Karza",
                  beneficiary_name_with_bank:
                    dealerDetails?.penny_drop_data?.data
                      ?.beneficiary_name_with_bank ||
                    dealerDetails?.penny_drop_data?.data?.accountName,
                },
                ...(dealerDetails?.security_cheques?.length > 0
                  ? {
                      security_cheques: {
                        name: "anchor_security_cheque",
                        file: (dealerDetails?.security_cheques || [])?.map(
                          (file) => file?.fileLink
                        ),
                      },
                    }
                  : {}),
                ...(dealerDetails?.bank_statements?.length > 0
                  ? {
                      bank_statement: {
                        name: "anchor_bank_statement",
                        file: (dealerDetails?.bank_statements || [])?.map(
                          (file) => file?.fileLink
                        ),
                      },
                    }
                  : {}),
              },
            ],
          }: {}),
        outlet_details: (dealerDetails?.outlet_details || [])?.map((val) => {
          return {
            pincode: val?.pincode,
            address: val?.address,
            country: val?.country,
            state: val?.state,
            city: val?.city,
            relation_manager_details: val?.relation_manager_details,
          };
        }),

        hold_back_type: dealerDetails?.hold_back_type,
        ...(dealerDetails?.hold_back_type === "DEALER"
          ? {
              hold_back_amount: {
                per_file: dealerDetails?.anchor_hold_back_per_file_amount,
                total: dealerDetails?.anchor_hold_back_total_amount,
              },
            }
          : {}),
        parent_anchor_id: dealerDetails?.parent_anchor_id,
      };
      let { data } = await createAnchorV2({
        document_included_g: true,
        ...payload,
      });
      await handleShopImagesUpload(null,data?.data?.anchor_id);
      setFailedDocs(data?.documentsNotUploaded || []);
      toast(data?.message || "Anchor Created Successfully");
      navigate(`/partners`);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Something wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setDealerDetails({
        anchor_id: "",
        oems: [{}],
      });
    };
  }, []);

  {
    /* {failedDocs?.length > 0 && (
                <>
                  <p className="mb-0" style={{ color: "red" }}>
                    Documents not Uploaded
                  </p>
                  {failedDocs?.map((val) => {
                    return (
                      <div style={{ marginLeft: 10 }}>- {val?.name}</div>
                    );
                  })}
                </>
              )} */
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="update-anchor-wrapper">
          <div className="update-anchor-sidebar">
            <div
              style={{
                cursor: "pointer",
                marginBottom: 30,
              }}
              onClick={() => {
                if (state?.path) {
                  navigate(state?.path);
                } else {
                  navigate("/partners");
                }
              }}
            >
              <ArrowBackIcon /> Go back Home
            </div>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                        {index !== steps.length - 1 && (
                          <Button
                            variant="outlined"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1, borderRadius: 30 }}
                          >
                            Next
                          </Button>
                        )}
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            <Button
              className="mb-5 anchor-update-btn"
              fullWidth
              variant="contained"
              onClick={handleCreateAnchor}
              disabled={(() => {
                if (isLoading) return true;
                if ([null, undefined, ""].includes(dealerDetails?.name))
                  return true;
                if (
                  [null, undefined, ""].includes(dealerDetails?.dealership_name)
                )
                  return true;
                if (typeof dealerDetails?.type !== "string") return true;
                if (
                  typeof dealerDetails?.oems?.[dealerDetails?.oems?.length - 1]
                    ?.oem_id !== "string"
                )
                  return true;
                if ([null, undefined, ""].includes(dealerDetails?.dob))
                  return true;
              })()}
            >
              {isLoading ? "Loading" : "Submit"}
            </Button>
          </div>
          <div className="section">
            <div className="steps-holder mb-5">
              <div className="">
                {
                  <Grid
                    container
                    spacing={2}
                    className="pb-3"
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      zIndex: 5,
                      position: "sticky",
                      top: -25,
                    }}
                  >
                    <Grid item xs={12}>
                      <div>
                        <h5
                          style={{
                            color: "#2C71C2",
                          }}
                        >
                          {steps[activeStep]}
                        </h5>
                      </div>
                    </Grid>
                  </Grid>
                }
                {getStepContent(activeStep, setActiveStep, { pennyDropLogs })}
              </div>
              <div style={{ marginBottom: 50 }}>&nbsp;</div>
            </div>
          </div>
        </div>
      </ThemeProvider>

      {overLoading?.open && <LoadingOver text={overLoading?.text} />}
    </>
  );
}

export default CreateAnchor;
