import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { getGpsCitywise, getGpsMovTrend, getGpsOemwise } from "../../../api";
import moment from "moment/moment";
import CustomTableV2 from "../../../Components/CustomTable/CustomTableV2";
import MovementTableGraph from "../../../Components/gps/MovementTableGraph";
import { toast } from "react-toastify";
import LoadingDiv from "../../../Components/gps/LoadingDiv";

const Movement = ({
  trendState,
  cityState,
  oemState,
  cityfilterState,
  oemfilterState,
}) => {
  const [trendData, setTrendData] = trendState;
  const [cityWiseData, setCityWiseData] = cityState;
  const [oemWiseData, setOemWiseData] = oemState;
  const [cityFilter, setCityFilter] = cityfilterState;
  const [oemFilter, setOemFilter] = oemfilterState;

  const [loadingTrend, setLoadingTrend] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [loadingOem, setLoadingOem] = useState(false);

  const handleLoadTrendData = async (filter) => {
    try {
      setLoadingTrend(true);
      const { data } = await getGpsMovTrend();

      const numEntries = data?.data?.length;
      const perc_sums = {};

      const percentageFields = [
        "12-20km",
        "20-50km",
        "50-75km",
        "75-100km",
        "100+km",
      ];

      const sums = (data?.data || [])?.reduce((acc, entry) => {
        for (const key in entry) {
          if (key !== "record_date") {
            acc[key] = (acc[key] || 0) + entry[key];
          }
          if (percentageFields.includes(key)) {
            perc_sums[key] =
              (perc_sums[key] || 0) +
              (entry[key] / entry["active"]) * 100;
          }
        }
        return acc;
      }, {});

      // Calculate averages
      const averages = {};
      const percentages = {};
      for (const key in sums) {
        averages[key] = (sums[key] / numEntries).toFixed(2);
      }
      for (const key in perc_sums) {
        percentages[`percentage_${key}`] = (
          perc_sums[key] / numEntries
        ).toFixed(2);
      }

      // Calculate inactive percentage
      const inactivePercentage = (sums.inactive / sums.total_vehicles) * 100;

      const countAvg = {
        label: "AVG",
        date: "-----------",
        active: averages.active,
        inactive: averages.inactive,
        inactive_percentage: inactivePercentage.toFixed(2),
      };

      const kmAvg = {
        label: "AVG",
        date: "-----------",
        "12_20": `${averages?.["12-20km"]} (${percentages?.["percentage_12-20km"]}%)`,
        "20_50": `${averages?.["20-50km"]} (${percentages?.["percentage_20-50km"]}%)`,
        "50_75": `${averages?.["50-75km"]} (${percentages?.["percentage_50-75km"]}%)`,
        "75_100": `${averages?.["75-100km"]} (${percentages?.["percentage_75-100km"]}%)`,
        "100_plus": `${averages?.["100+km"]} (${percentages?.["percentage_100+km"]}%)`,
      };

      setTrendData({
        loaded: true,
        data: data?.data || [],
        avg: {
          countAvg,
          kmAvg,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoadingTrend(false);
  };

  let trend_count_column = [
    {
      label: "Day",
      render: (index) => {
        return moment(
          trendData?.data?.[index]?.record_date,
          "DD/MM/YYYY"
        ).format("dddd");
      },
      width: 150,
    },
    {
      label: "Date",
      render: (index) => {
        return trendData?.data?.[index]?.record_date;
      },
      width: 150,
    },
    {
      label: "Active",
      render: (index) => {
        return trendData?.data?.[index]?.active;
      },
      width: 150,
    },
    {
      label: "Inactive",
      render: (index) => {
        return trendData?.data?.[index]?.inactive;
      },
      width: 150,
    },
    {
      label: "Inactive %",
      render: (index) => {
        return `${(
          (Number(trendData?.data?.[index]?.inactive) /
            Number(trendData?.data?.[index]?.total_vehicles)) *
          100
        )?.toFixed(2)}%`;
      },
      width: 150,
    },
  ];

  const findPercentage = (count, total) => {
    return ((Number(count) / Number(total)) * 100)?.toFixed(2) + "%";
  };

  const renderKMColumn = (key, index) => {
    return (
      <span>
        {trendData?.data?.[index]?.[key]} (
        {findPercentage(
          trendData?.data?.[index]?.[key],
          trendData?.data?.[index]?.active
        )}
        )
      </span>
    );
  };

  let trend_km_column = [
    {
      label: "Day",
      render: (index) => {
        return moment(
          trendData?.data?.[index]?.record_date,
          "DD/MM/YYYY"
        ).format("dddd");
      },
      width: 150,
    },
    {
      label: "Date",
      render: (index) => {
        return trendData?.data?.[index]?.record_date;
      },
      width: 150,
    },
    {
      label: "12-20km",
      render: (index) => {
        return renderKMColumn("12-20km", index);
      },
      width: 150,
    },
    {
      label: "20-50km",
      render: (index) => {
        return renderKMColumn("20-50km", index);
      },
      width: 150,
    },
    {
      label: "50-75km",
      render: (index) => {
        return renderKMColumn("50-75km", index);
      },
      width: 150,
    },
    {
      label: "75-100km",
      render: (index) => {
        return renderKMColumn("75-100km", index);
      },
      width: 150,
    },
    {
      label: "100+km",
      render: (index) => {
        return renderKMColumn("100+km", index);
      },
      width: 150,
    },
  ];

  const getOemCitywiseColmn = (type, tableData) => {
    return [
      {
        label: type,
        render: (index) => {
          return tableData?.[index]?.[type?.toLowerCase()];
        },
        width: 150,
      },
      {
        label: "Vehicles",
        render: (index) => {
          return tableData?.[index]?.active_vehicle_count;
        },
        width: 150,
      },
      {
        label: "AVG KM Per day",
        render: (index) => {
          return tableData?.[index]?.average_kms_per_day;
        },
        width: 150,
      },
    ];
  };

  const handleLoadData = async (
    filter,
    setLoading,
    setFilter,
    getDataFunc,
    setWiseData,
    type
  ) => {
    try {
      setLoading(true);
      setFilter(filter);
      const { data } = await getDataFunc(filter ? `?sort=${filter}` : "");
      let graphData = {
        labels: [],
        values: [],
      };

      for (let item of data?.data || []) {
        graphData.labels.push(item?.[type]);
        graphData.values.push(item?.average_kms_per_day);
      }
      setWiseData({
        loaded: true,
        data: data?.data,
        graphData,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
    setLoading(false);
  };

  const handleLoadCityData = async (filter) => {
    await handleLoadData(
      filter,
      setLoadingCity,
      setCityFilter,
      getGpsCitywise,
      setCityWiseData,
      "city"
    );
  };

  const handleLoadOemData = async (filter) => {
    await handleLoadData(
      filter,
      setLoadingOem,
      setOemFilter,
      getGpsOemwise,
      setOemWiseData,
      "oem"
    );
  };

  useEffect(() => {
    if (!trendData?.loaded) {
      handleLoadTrendData();
    }
    if (!cityWiseData?.loaded) {
      handleLoadCityData(cityFilter);
    }
    if (!oemWiseData?.loaded) {
      handleLoadOemData(oemFilter);
    }
  }, []);

  return (
    <div>
      <p style={{ fontWeight: 500 }}>Last 7 days Movement Trend</p>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          {loadingTrend ? (
            <LoadingDiv />
          ) : (
            <>
              <CustomTableV2
                tableData={trendData?.data || []}
                columns={trend_count_column}
                tableHeight={"100%"}
                limit={0}
                page={1}
                isLoading={loadingTrend}
                maxHeight={440}
                borderBottom
                // headerComp={<div>{filterValues?.[selectedFilterIns]}</div>}
                totalRow={trendData?.avg?.countAvg}
              />
            </>
          )}
        </Grid>
        <Grid item xs={7}>
          {loadingTrend ? (
            <LoadingDiv />
          ) : (
            <CustomTableV2
              tableData={trendData?.data || []}
              columns={trend_km_column}
              tableHeight={"100%"}
              limit={0}
              page={1}
              isLoading={loadingTrend}
              maxHeight={440}
              borderBottom
              // headerComp={<div>{filterValues?.[selectedFilterIns]}</div>}
              totalRow={trendData?.avg?.kmAvg}
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} className="mt-4">
        <MovementTableGraph
          tableData={cityWiseData?.data || []}
          columns={getOemCitywiseColmn("City", cityWiseData?.data || [])}
          graphData={
            cityWiseData?.graphData || {
              labels: [],
              values: [],
            }
          }
          isloading={loadingCity}
          header={"City wise"}
          currentFilter={cityFilter}
          onFilterChange={(filter) => {
            handleLoadCityData(filter);
          }}
        />

        <MovementTableGraph
          tableData={oemWiseData?.data || []}
          columns={getOemCitywiseColmn("OEM", oemWiseData?.data || [])}
          graphData={
            oemWiseData?.graphData || {
              labels: [],
              values: [],
            }
          }
          isloading={loadingOem}
          header={"OEM wise"}
          currentFilter={oemFilter}
          onFilterChange={(filter) => {
            handleLoadOemData(filter);
          }}
        />
      </Grid>
    </div>
  );
};

export default Movement;
