export const formatAnchorPayloadUpdate = (
  dealerDetails,
  updateDocs,
  bank_id,
  penny_drop_data,
  dealerDetailsFullData
) => {
  let anchorDocs = [];
  let bankDocs = [];
  let bankCategory = [
    "security_cheques",
    "last_6_months_bank_statement",
    "bank_statement",
  ];
  updateDocs?.forEach((val) => {
    if (bankCategory?.includes(val?.type)) {
      bankDocs.push(val);
    } else {
      anchorDocs.push(val);
    }
  });

  let anchorUpdate = {
    name: dealerDetails?.name,
    firm_type: dealerDetails?.type,
    dealership_name: dealerDetails?.dealership_name,
    dob: dealerDetails?.dob,
    gst: dealerDetails?.gstin,
    pan_details: dealerDetails?.pan_details,
    rto_name: dealerDetails?.rto_name,
    monthly_capacity: dealerDetails?.monthly_capacity,
    oem_details: dealerDetails?.oems?.map((oem) => {
      return {
        oem_id: oem?.oem_id,
        oem_name: oem?.oem_name,
        battery_info: (() => {
          let array = [];
          Object.keys(oem?.battery_type || {})?.forEach((key) => {
            if (oem?.battery_type[key]?.checked) {
              array.push({
                battery_type: key,
                max_loan_amount: oem?.battery_type[key]?.amount,
              });
            }
          });
          return array;
        })(),
        interest_rate: oem?.interest_rate,
        processing_fees:
          oem?.pf_type === "AMOUNT" ? oem?.processing_fees : null,
        processing_fees_percent:
          oem?.pf_type === "PERCENTAGE" ? oem?.processing_fees_percent : null,
        hold_back_amount: {
          per_file: oem?.hold_back_per_file_amount,
          total: oem?.hold_back_total_amount,
        },
        subvention: {
          amount: oem?.sb_type === "AMOUNT" ? oem?.subvention_amount : null,
          percent: oem?.sb_type === "PERCENTAGE" ? oem?.subvention_perc : null,
          gst_type: oem?.gst_type
        },
        gps_charges: oem?.gps_charges,
      };
    }),
    outlet_details: dealerDetails?.outlet_details?.map((val) => {
      return {
        pincode: val?.pincode,
        address: val?.address,
        country: val?.country,
        state: val?.state,
        city: val?.city,
        relation_manager_details: val?.relation_manager_details,
      };
    }),
    hold_back_type: dealerDetails?.hold_back_type,
    ...(dealerDetails?.anchor_hold_back_per_file_amount ||
      dealerDetails?.anchor_hold_back_total_amount
      ? {
        hold_back_amount: {
          per_file: dealerDetailsFullData?.anchor_hold_back_per_file_amount,
          total: dealerDetailsFullData?.anchor_hold_back_total_amount,
        },
      }
      : undefined),
    parent_anchor_id: dealerDetails?.parent_anchor_id,
  };

  if (anchorUpdate?.hold_back_type === "OEM") {
    delete anchorUpdate?.hold_back_amount
  }

  let bankUpdate = removeUndefinedKeys({
    account_number: dealerDetails?.account_number,
    ifsc_code: dealerDetails?.ifsc_code,
    beneficiary_name: dealerDetails?.beneficiary_name,
    bank_name: dealerDetails?.bank_name,
    account_type: dealerDetails?.account_type,
    penny_drop_data: penny_drop_data,
  });

  let payload = {
    anchorUpdateQuery: removeUndefinedKeys(anchorUpdate),
    anchorDocuments: anchorDocs,
    ...(Object.keys(bankUpdate)?.length > 0 || bankDocs?.length > 0
      ? {
        bankUpdateArray: [
          {
            updateQuery: bankUpdate,
            bankDocuments: bankDocs,
            ...(typeof bank_id === "string"
              ? { bank_id, new: false }
              : { new: true }),
          },
        ],
      }
      : {}),
  };
  return payload;
};

function removeUndefinedKeys(obj) {
  const cleanObject = {};
  for (let key in obj) {
    if (obj[key] !== undefined) {
      cleanObject[key] = obj[key];
    }
  }
  return cleanObject;
}
