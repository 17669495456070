import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  Slide,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useApplication from "../hooks/useApplication";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import dayjs from "dayjs";
import LoadingOver from "./Loading";
import { toast } from "react-toastify";
import { addNachPresent } from "../api";
import { f } from "../utility/Formater";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initNachData = {
  settlement_dateMS: null,
  amount: 0,
};

function AddNachPresent({ open, handleClose }) {
  const { updateDetails, getApplicationDetails } = useApplication();
  const { collectionData = {} } = updateDetails;
  const { next_schedule } = collectionData;
  const [nachData, setNachData] = useState(initNachData);
  const [loading, setLoading] = useState(null);
  const [cnfmSbmt, setCnfmSbmt] = useState(false);

  const { first_collection_date, last_collection_date } =
    updateDetails?.digio_nach_data?.latest?.mandate_details || {};

  function handleChange(key, value) {
    setNachData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  async function handleSubmit() {
    try {
      setLoading("adding new nach present...");
      setCnfmSbmt(false);
      if (!updateDetails?.digio_nach_data?.latest?.umrn) {
        toast.error("umrn not found");
        return;
      }
      if (dayjs(nachData?.settlement_dateMS).isBefore(dayjs())) {
        toast.error(`Please select the future date.`);
        return;
      }
      if(dayjs(nachData?.settlement_dateMS).isBefore(dayjs(first_collection_date))){
        toast.error(`Please select a date that comes after the first collection date.`);
        return;
      }
      let payload = {
        application_id: updateDetails?.application_id,
        settlement_date: dayjs(nachData?.settlement_dateMS).format(
          "YYYY-MM-DD"
        ),
        amount: +nachData.amount,
        umrn: updateDetails?.digio_nach_data?.latest?.umrn,
        nbfc_id: updateDetails?.nbfc_id,
      };
      const { data } = await addNachPresent(payload);
      toast(data?.message);
      await getApplicationDetails(updateDetails?.application_id);
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(null);
    }
  }

  useEffect(() => {
    if (open) {
      setNachData({
        amount: next_schedule?.nextInstallmentAmount,
        settlement_dateMS: null,
      });
    } else {
      setNachData({ ...initNachData });
    }
  }, [open]);

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Nach Presentation - {updateDetails?.customer_name}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <div className="d-flex flex-row align-items-center w-100 justify-content-between mb-3">
                <span className="d-flex flex-column justify-content-start align-items-start">
                  <span style={{ fontSize: "10px" }}>First Collection Date</span>
                  <span className="fs-6">
                    {dayjs(first_collection_date, {
                      format: "DD-MM-YYYY HH:mm:ss",
                    }).format("DD-MM-YYYY")}
                  </span>
                </span>
                <span>
                  <EastOutlinedIcon />
                </span>
                <span className="d-flex flex-column justify-content-start align-items-start">
                  <span style={{ fontSize: "10px" }}>Last Collection Date</span>
                  <span className="fs-6">
                    {last_collection_date
                      ? dayjs(last_collection_date, {
                          format: "DD-MM-YYYY HH:mm:ss",
                        }).format("DD-MM-YYYY")
                      : f(last_collection_date)}
                  </span>
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl>
                <FormLabel>
                  Amount<span className="text-danger">&nbsp;*</span>
                </FormLabel>
                <TextField
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={nachData?.amount}
                  onChange={(event) => {
                    const sanitizedValue = event.target.value.replace(
                      /[^0-9.]/g,
                      ""
                    );
                    handleChange("amount", sanitizedValue);
                  }}
                  InputProps={{
                    pattern: "[0-9]*",
                    endAdornment: (
                      <InputAdornment position="end">
                        <CurrencyRupeeIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={10} mt={2}>
              <FormControl>
                <FormLabel>
                  Settlement Date<span className="text-danger">&nbsp;*</span>
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box>
                    <DemoItem>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disablePast
                        minDate={dayjs(first_collection_date,{format:"YYYY-MM-DD HH:mm:ss"})}
                        value={nachData?.settlement_dateMS}
                        inputVariant="outlined"
                        onChange={(date) => {
                          handleChange("settlement_dateMS", date);
                        }}
                        animateYearScrolling
                      />
                    </DemoItem>
                  </Box>
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setCnfmSbmt(true);
            }}
            disabled={!nachData?.amount || !nachData?.settlement_dateMS}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={cnfmSbmt}
        onClose={() => {
          setCnfmSbmt(false);
        }}
      >
        <DialogTitle>
          Are you sure you want to present nach for this customer ?
        </DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            onClick={() => {
              setCnfmSbmt(false);
            }}
          >
            Go back
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            {"Present "}
            <ArrowForwardIosIcon fontSize="small" />
          </Button>
        </DialogActions>
      </Dialog>
      {Boolean(loading) && <LoadingOver text={loading} />}
    </>
  );
}

export default AddNachPresent;
