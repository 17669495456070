import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { fetchAllApplications2, fetchDealerScore } from "../../api";
import LoadingOver from "../../Components/Loading";
import scoreImg from "../../assets/dealer-score.jpg";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";

const DealerScore = ({ isDialog, setOpenDealerScore }) => {
  const [isLoading, setLoading] = useState();
  const [scoreData, setScoreData] = useState([]);
  const [sumScore, setSumScore] = useState([]);

  const generateScore = async () => {
    setLoading(true);
    try {
      let { data } = await fetchDealerScore(
        null,
        "?update_db=true&app_level=true&sheet=true"
      );
      setScoreData(data?.data?.application_level || []);
      setSumScore(data?.data?.final_data || []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleDownload = (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = [
      `Application ID,Anchor ID,Anchor name, Creation, Disbursed, Rejected, Nach Registered, Nach Registed not success, Nach not Registered, GPS is Present, GPS not Present, RC Uploaded, RC not uploaded within 45 days, Courier sent within 15 days,Courier not sent within 15 days, TAT creation to Disbursal <=5,TAT creation to Disbursal btw 5-8, TAT creation to Disbursal btw 8-10, TAT creation to Disbursal btw 10-15, TAT creation to Disbursal >= 15,Time btw 2 Apps <=2, Time btw 2 Apps btw 2-3, Time btw 2 Apps btw 3-4, Time btw 2 Apps btw 4-6, Time btw 2 Apps >=6, Collection on or Before Due Date, Collection within 7 days,Collection within 7-15 days, Collection within 15-30 days, Collection comes after 30 days or not received, TOTAL`,
    ];

    // Convert users data to a csv
    let usersCsv = scoreData.reduce((acc, row) => {
      acc.push(
        [
          row?.application_id,
          row?.anchor_id,
          row?.anchor_name,
          row?.creation,
          row?.disburment,
          row?.rejection,
          row?.nach_registered,
          row?.nach_registered_nosuccess,
          row?.nach_id_not_exist,
          row?.gps,
          row?.gps_not,
          row?.rc_uploaded,
          row?.rc_not_uploaded_before_45,
          row?.courier_within_15,
          row?.courier_not_within_15,
          row?.tat_less_5,
          row?.tat_between_5_8,
          row?.tat_between_8_10,
          row?.tat_between_10_15,
          row?.tat_greater_15,
          row?.app_diff_less_2,
          row?.app_diff_between_2_3,
          row?.app_diff_between_3_4,
          row?.app_diff_between_4_6,
          row?.app_diff_greater_6,
          row?.collection_before_due,
          row?.collection_within_7,
          row?.collection_within_7_15,
          row?.collection_within_15_30,
          row?.collection_after_30_not,
          row?.total,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: `Dealer Score`,
      fileType: "text/csv",
    });
  };

  const handleDownloadFinal = (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = [
      `Anchor ID,Anchor name,Application Level Score, Monthwise Score, Status, Disbursed Count, Borrower Score, Total`,
    ];

    // Convert users data to a csv
    let usersCsv = sumScore.reduce((acc, row) => {
      acc.push(
        [
          row?.anchor_id,
          row?.anchor_name,
          row?.application_level_score,
          row?.month_wise_score,
          row?.status,
          row?.disburment,
          row?.borrower_score,
          row?.total,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: `Dealer Score summed`,
      fileType: "text/csv",
    });
  };

  return (
    <>
      <div
        className="d-flex"
        style={{
          width: "100%",
          height: isDialog ? "100%" : "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="dealer-score-holder" style={{ boxShadow: "none" }}>
          <div className="content-center">
            <img src={scoreImg} width={200} />
          </div>
          <div className="content-center">
            <Button
              className="mt-2"
              variant="contained"
              onClick={generateScore}
              sx={{ fontSize: 12 }}
            >
              Generate Score {!isLoading && scoreData?.length > 0 && "Again"}
            </Button>
          </div>
          <div>
            {!isLoading && scoreData?.length > 0 && (
              <>
                <Button sx={{ fontSize: 12 }} onClick={handleDownload}>
                  <DownloadIcon sx={{ fontSize: 15 }} /> Download Sheet
                </Button>
                <Button sx={{ fontSize: 12 }} onClick={handleDownloadFinal}>
                  <DownloadIcon sx={{ fontSize: 15 }} /> Download overall Sheet
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      {isLoading && <LoadingOver text={"Calculating Score"} />}
    </>
  );
};

export default DealerScore;
