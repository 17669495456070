import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useContext, useState } from "react";
import { GlobalContext } from "../Context/GlobalContext";
import { useEffect } from "react";
import { getUserByDepartment, updateDummyStage } from "../api";
import { toast } from "react-toastify";
import { CircularProgress, Dialog, TextField } from "@mui/material";
// import { salesAgents, usersMail } from "../utility/StagesV2";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

// const stageMap = {
//   0: { key: "ready_to_credit_check", text: "Ready to credit check" },
//   1: { key: "credit_checker", text: "Assign credit checker" },
//   2: { key: "ready_for_disbursal", text: "Ready for disbursal" },
//   3: { key: "money_received", text: "Money Received" },
// };

const stageMap = {
  0: { key: "sales_agent", text: "Assign Business Executive" },
  1: { key: "ready_to_credit_check", text: "Ready to credit check" },
  2: { key: "credit_checker", text: "Assign credit checker" },
  3: { key: "ready_for_disbursal", text: "Ready for disbursal" },
  4: { key: "money_received", text: "Money Received" },
};

const keyMap = {
  sales_agent: 0,
  ready_to_credit_check: 1,
  credit_checker: 2,
  ready_for_disbursal: 3,
  money_received: 4,
};

export default function DummyStagePicker({ applicationId }) {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCredit, setAnchorElCredit] = useState(null);
  const [anchorElSales, setAnchorElSales] = useState(null);
  const [activeStage, setActiveStage] = useState(-1);
  const [tempStage, setTempStage] = useState(-1);
  const [creditChecker, setCreditChecker] = useState("");
  const [salesAgent, setSalesAgent] = useState("");
  const [moneyDate, setMoneyDate] = useState(new Date());
  const [isLoading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [usersList, setUsersList] = useState({ SALES: [], CREDIT: [] });
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const { state } = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreditClick = (event) => {
    setAnchorElCredit(event.currentTarget);
  };
  const handleCreditClose = () => {
    setAnchorElCredit(null);
  };

  const handleSalesClick = (event) => {
    setAnchorElSales(event.currentTarget);
  };
  const handleSalesClose = () => {
    setAnchorElSales(null);
  };

  const findStage = () => {
    let stageObj = updateDetails?.additional_stages || {};
    let keys = Object.keys(stageObj);
    for (const key of keys.reverse()) {
      console.log(key, stageObj?.[key] ? "Yes" : "No", keyMap?.[key]);
      if (stageObj?.[key]) {
        if (keyMap?.[key] || keyMap?.[key] === 0) {
          return keyMap?.[key];
          break;
        }
      }
    }
    return -1;
  };

  const handleUpdateStage = async (initial = false) => {
    setLoading(true);
    try {
      let payload;
      let key = stageMap?.[tempStage]?.key;
      if (initial) {
        payload = {
          application_id: applicationId,
          sales_agent: {
            status: true,
            email: salesAgent,
          },
        };
      } else {
        payload = {
          application_id: applicationId,
          [key]: {
            status: true,
            ...(tempStage === 0 ? { email: salesAgent } : {}),
            ...(tempStage === 2 ? { email: creditChecker } : {}),
            ...(tempStage === 4 ? { date: moneyDate } : {}),
          },
        };
      }
      let { data } = await updateDummyStage(payload);
      setUpdateDetails({
        ...updateDetails,
        additional_stages: data?.additional_stages || {},
      });
      handleClose();
      if (openPopup) {
        setOpenPopup(false);
      }
      toast.success(
        initial
          ? "Sales agent assigned!"
          : "Stage updated to " + stageMap?.[tempStage]?.text
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
    setLoading(false);
  };

  const handleGetUsers = async () => {
    try {
      let { data } = await getUserByDepartment(
        JSON.stringify(["CREDIT_CHECK", "SALES"])
      );
      const users = data?.users || [];
      const salesUsers = users.filter((user) =>
        user.departments.includes("SALES")
      );
      const creditCheckUsers = users.filter((user) =>
        user.departments.includes("CREDIT_CHECK")
      );

      setUsersList({
        SALES: salesUsers,
        CREDIT: creditCheckUsers,
      });
    } catch (error) {
      toast.error("Failed to fetch users List");
    }
  };

  useEffect(() => {
    setActiveStage(findStage());
  }, [updateDetails?.additional_stages]);

  useEffect(() => {
    if (open) {
      (async () => {
        await handleGetUsers();
      })();
      setSalesAgent(updateDetails?.additional_stages?.sales_agent?.email || "");
      setCreditChecker(
        updateDetails?.additional_stages?.credit_checker?.email || ""
      );
      setMoneyDate(
        new Date(
          updateDetails?.additional_stages?.money_received?.dateMS || new Date()
        )
      );
      setTempStage(activeStage);
    }
    if (!open) {
      setTempStage(-1);
    }
  }, [activeStage, open]);

  useEffect(() => {
    if (updateDetails?.application_id) {
      if (
        updateDetails?.additional_stages?.hasOwnProperty("sales_agent") &&
        updateDetails?.additional_stages?.sales_agent === null
      ) {
        setOpenPopup(true);
      } else {
        setOpenPopup(false);
      }
    }
  }, [activeStage]);

  useEffect(() => {
    if (openPopup) {
      (async () => {
        await handleGetUsers();
      })();
    }
  }, [openPopup]);

  return (
    <>
      <div>
        <Button
          id="demo-positioned-button w-100"
          variant="contained"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{
            background: "white",
            color: "black",
            fontWeight: 400,
            marginTop: -10,
          }}
          onClick={handleClick}
        >
          {stageMap?.[activeStage]?.text || "Select"} <ArrowDropDownIcon />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="px-4 py-2">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={activeStage}
                name="radio-buttons-group"
                onChange={(e) => {
                  setTempStage(Number(e.target.value));
                }}
              >
                {/* Assign Sales Agent */}

                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={
                    <>
                      <div>
                        Assign Business Executive <ArrowRightIcon />
                      </div>
                    </>
                  }
                  onClick={handleSalesClick}
                />
                {(tempStage >= 0 || activeStage >= 0) && (
                  <div
                    style={{
                      color: "blue",
                      marginLeft: 30,
                      fontSize: 12,
                    }}
                  >
                    {salesAgent}
                  </div>
                )}

                <Menu
                  anchorEl={anchorElSales}
                  open={Boolean(anchorElSales)}
                  defaultValue={salesAgent}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    horizontal: "top",
                  }}
                  onClose={handleSalesClose}
                >
                  {usersList?.SALES?.map((user) => {
                    return (
                      <MenuItem
                        sx={{ color: "blue" }}
                        onClick={() => {
                          setSalesAgent(user?.email);
                          handleSalesClose();
                        }}
                        value={user?.email}
                      >
                        {user?.email}
                      </MenuItem>
                    );
                  })}
                </Menu>

                {/* Ready to credit check */}

                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Ready to credit check"
                />

                {/* Assign credit checker */}

                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={
                    <>
                      <div>
                        Assign credit checker <ArrowRightIcon />
                      </div>
                    </>
                  }
                  onClick={handleCreditClick}
                />
                {(tempStage >= 2 || activeStage >= 2) && (
                  <div
                    style={{
                      color: "blue",
                      marginLeft: 30,
                      fontSize: 12,
                    }}
                  >
                    {creditChecker}
                  </div>
                )}

                <Menu
                  anchorEl={anchorElCredit}
                  open={Boolean(anchorElCredit)}
                  defaultValue={creditChecker}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    horizontal: "top",
                  }}
                  onClose={handleCreditClose}
                >
                  {usersList?.CREDIT?.map((user) => {
                    return (
                      <MenuItem
                        sx={{ color: "blue" }}
                        onClick={() => {
                          setCreditChecker(user?.email);
                          handleCreditClose();
                        }}
                        value={user?.email}
                      >
                        {user?.email}
                      </MenuItem>
                    );
                  })}
                </Menu>

                {/* Ready for disbursal */}

                <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label="Ready for disbursal"
                />

                {/*Money Received */}

                <FormControlLabel
                  value={4}
                  control={<Radio />}
                  label="Money Received"
                />
                {(tempStage === 4 || activeStage === 4) && (
                  <div
                    style={{
                      color: "blue",
                      marginLeft: 30,
                      marginBottom: 30,
                      fontSize: 12,
                    }}
                  >
                    <TextField
                      type="date"
                      className="w-100"
                      variant="standard"
                      value={moment(moneyDate).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        console.log(new Date(e.target.value));
                        setMoneyDate(new Date(e.target.value));
                      }}
                    />
                  </div>
                )}
              </RadioGroup>
            </FormControl>
            <div className="w-100 d-flex justify-content-end">
              {isLoading ? (
                <>
                  <Button>
                    <CircularProgress sx={{ mr: 1 }} size={15} /> Updating
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  disabled={(() => {
                    if (tempStage < 0) return true;
                    if (tempStage === 0 && salesAgent === "") {
                      return true;
                    } else if (tempStage === 2 && creditChecker === "") {
                      return true;
                    } else if (tempStage === activeStage) {
                      if (activeStage === 0) {
                        if (
                          salesAgent ===
                          updateDetails?.additional_stages?.sales_agent?.email
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      } else if (activeStage === 2) {
                        if (
                          creditChecker ===
                          updateDetails?.additional_stages?.credit_checker
                            ?.email
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      } else if (activeStage === 4) {
                        return false;
                      }
                      return true;
                    } else {
                      return false;
                    }
                  })()}
                  onClick={() => handleUpdateStage()}
                >
                  Select
                </Button>
              )}
            </div>
          </div>
        </Menu>
      </div>

      <Dialog open={openPopup}>
        <div className="px-3 py-3">
          <p className="mb-2">Assign Business Executive</p>
          <Button
            id="demo-positioned-button w-100"
            variant="contained"
            aria-controls={"demo-positioned-menu"}
            aria-haspopup="true"
            aria-expanded={"true"}
            style={{
              minWidth: 300,
              background: "white",
              color: "black",
              fontWeight: 400,
            }}
            onClick={handleSalesClick}
          >
            <div
              className="d-flex w-100"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div>{!salesAgent ? "Select an email" : salesAgent}</div>
              <ArrowDropDownIcon />
            </div>
          </Button>

          <Menu
            anchorEl={anchorElSales}
            open={Boolean(anchorElSales)}
            defaultValue={salesAgent}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "bottom",
            }}
            transformOrigin={{
              horizontal: "top",
            }}
            onClose={handleSalesClose}
          >
            {usersList?.SALES?.map((user) => {
              return (
                <MenuItem
                  sx={{ color: "blue" }}
                  onClick={() => {
                    setSalesAgent(user?.email);
                    handleSalesClose();
                  }}
                  value={user?.email}
                >
                  {user?.email}
                </MenuItem>
              );
            })}
          </Menu>

          <div className="w-100 d-flex justify-content-end mt-3">
            <Button
              className="me-2"
              onClick={() => {
                if (state?.path) {
                  navigate("/" + state?.path);
                } else {
                  navigate("/loan-applications");
                }
              }}
            >
              Go Back
            </Button>
            {isLoading ? (
              <>
                <Button>
                  <CircularProgress sx={{ mr: 1 }} size={15} /> Assiging...
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                disabled={salesAgent?.length === 0}
                onClick={() => handleUpdateStage(true)}
              >
                Assign
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}
